<template>
  <div class="his_edit_opinion">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: '/cases/detail',
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="$t('casesDetail.casesChildren.hisTextPlan.wzlsxgyj')"
    />
    <h5 class="oper_tit">{{$t('casesDetail.casesChildren.hisTextPlan.fabh')}}：{{curePlanId}}</h5>
    <his-plan />
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import HisPlan from './textPlanChildren/hisPlan';
  export default {
    data() {
      return {
        curePlanId: ''
      }
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.hisTextPlan.wzlsxgyj');
      this.curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      const casesId = localStorage.getItem('casesId') || sessionStorage.getItem('casesId') || '';
      sessionStorage.setItem('curePlanId', this.curePlanId);
      sessionStorage.setItem('casesId', casesId);
      localStorage.removeItem('curePlanId');
      localStorage.removeItem('casesId');
    },
    components: {
      BackStep,
      HisPlan
    }
  }
</script>

<style scoped lang="scss">
  .his_edit_opinion {
    color: $main_theme_color_333;
    font-size: 0.16rem;
    width: 13.44rem;
    margin: 0 auto 1rem;
    .oper_tit {
      color: $main_theme_color_333;
      font-size: 0.24rem;
      margin-bottom: 0.2rem;
    }
    .oper_ul {
      display: flex;
      align-items: center;
      color: $main_theme_color_333;
      font-size: 0.18rem;
      border-bottom: #eee;
      span {
        display: inline-block;
      }
      .oper_single {
        width: 1.48rem;
        height: 0.46rem;
        line-height: 0.46rem;
        text-align: center;
        border-top-left-radius: 0.06rem;
        border-top-right-radius: 0.06rem;
        margin-right: 0.02rem;
        background-color: #fff;
        position: relative;
        cursor: pointer;
        .o_icon {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0.16rem;
          height: 0.08rem;
          background-size: 100% 100%;
          background-image: url("/static/images/nav_sp.png");
        }
      }
      .oper_single_active {
        background-color: $main_theme_color;
        color: #fff;
      }
    }
  }
</style>
